import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import marked from "marked"
import PageLayout from "../components/hbnfpagelayout"
import arrowblue from "../images/arrow_down_blue.png"
import arrowblack from "../images/arrow_down_black.png"
import Head from "../components/head"
export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      displayName
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      slug
      signUpForUpdatesUrl
      registrationUrl
      registrationCtaText
      transparentHeader
      backgroundImage {
        file {
          url
        }
      }
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      seoMetadata {
        title
        description {
          description
        }
      }
      faqSeoMetadata {
        title
        description {
          description
        }
      }
      faq {
        title
        details {
          details
        }
      }
    }
  }
`

const Faq = props => {
  let registerUrl
  if (
    typeof window !== "undefined" &&
    props?.data?.contentfulEvents?.registrationUrl
  ) {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl?.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
  })
  const accordionOpen = eve => {
    const accordionbody = document.getElementsByClassName("faq_acc_body")
    const parent = eve.target
    const nextchild = eve.target.nextSibling
    if (nextchild.style.minHeight === "0px") {
      for (let i = 0; i < accordionbody.length; i++) {
        accordionbody[i].style.minHeight = "0px"
        accordionbody[i].previousSibling.style.color = "#000000"

        accordionbody[
          i
        ].previousSibling.style.backgroundImage = `url(${arrowblack})`
      }
      parent.style.color = "#9D63FF"

      nextchild.style.minHeight = nextchild.scrollHeight + 20 + "px"

      nextchild.previousSibling.style.backgroundImage = `url(${arrowblue})`
    } else {
      nextchild.style.minHeight = "0px"
      nextchild.previousSibling.style.backgroundImage = `url(${arrowblack})`
      parent.style.color = "#000000"
    }
  }

  const getMarkdownText = aboutDetails => {
    if (aboutDetails !== null || aboutDetails !== undefined) {
      var rawMarkup = marked(aboutDetails, { sanitize: true })
      return { __html: rawMarkup }
    }
  }
  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      bannerBackground={props?.data?.contentfulEvents?.backgroundImage}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      signUpUrl={props?.data?.contentfulEvents?.signUpForUpdatesUrl}
      registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={props?.data?.contentfulEvents?.faqSeoMetadata?.title}
        description={
          props?.data?.contentfulEvents?.faqSeoMetadata?.description
            ?.description
        }
      />
      <div className="faq_body">
        <h2 className="faq_title">FAQ</h2>
        <div className="faq_acc_main">
          {props?.data?.contentfulEvents?.faq?.map((item, index) => {
            return (
              <>
                <h3 className="faq_acc_title" onClick={accordionOpen}>
                  {item.title}
                </h3>
                <div
                  className="faq_acc_body"
                  style={{ minHeight: "0px" }}
                  dangerouslySetInnerHTML={{
                    __html: marked.parse(item?.details?.details),
                  }}
                ></div>
              </>
            )
          })}
        </div>
      </div>
    </PageLayout>
  )
}
export default Faq
